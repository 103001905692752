<template>
  <b-modal
    id="recapito-organization-modal-event-handler"
    ref="recapito-organization-modal-event-handler"
    centered
    :title="title"
    hide-footer
    :visible="show"
    no-close-on-backdrop
    @hidden="(() => {
      show = false
      resetModal()
    })"
  >
    s{{ recapito }}
    <validation-observer ref="set-contact-form">
      <b-row
        no-gutters
      >
        <b-col
          cols="12"
          lg="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Tipo di Contatto"
            rules="required"
          >
            <b-form-group
              :label="'Tipo di Contatto'"
              label-class="font-weight-bolder"
            >
              <v-select
                v-model="recapito.tipo"
                class="mr-lg-1"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                filterable
                :options="optionContacts"
                :reduce="(option => option)"
                placeholder="Tipo di Contatto"
              />
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Tipo di Contatto"
            rules="required"
          >
            <b-form-group
              :label="'Indirizzo'"
              label-class="font-weight-bolder"
            >
              <b-form-input
                v-model="recapito.valore"
                placeholder="valore"
              />
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <b-button
      variant="primary"
      @click="addContact"
    >Salva</b-button>
    <!-- {{ idRecapito }}
    <br>
    {{ contact }}
    <br>
    contactToSet {{ contactToSet }} -->
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BFormGroup, BFormInput, BCol, BRow, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    vSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
  },
  data() {
    return {
      title: '',
      show: false,
      recapito: {
        id: 0,
        tipo: '',
        valore: '',
      },
      optionContacts: ['Tel', 'Pec', 'Email', 'Website'],
      /* { value: 'tel', text: 'Tel', selected: true },
        { value: 'pec', text: 'Pec' },
        { value: 'email', text: 'Email' },
        { value: 'website', text: 'SitoWeb' },
      ], */
      valueContact: '',
      contact: {
        typeContact: {},
        valore: '',
      },
    }
  },
  computed: {
    contactComputed() {
      // console.log(this.contactToSet)
      return this.contactToSet
    },
  },
  mounted() {
    // console.log('ho creato la modal')
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    setRecapito(recapito) {
      this.recapito = { ...recapito }
    },
    addContact() {
      this.$refs['set-contact-form'].validate().then(async success => {
        if (success) {
          this.$emit('update-recapito', this.recapito)
          this.$refs['recapito-organization-modal-event-handler'].hide()
        }
      })
    },
    showModal() {
      this.show = true
    },
    resetModal() {
      this.recapito = {
        id: 0,
        tipo: '',
        valore: '',
      }
    },
  },
}
</script>

<style>

</style>
