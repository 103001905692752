<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        class=""
      >
        <div>
          <b-img
            rounded
            :src="organizzazione.avatar"
            :width="'100px'"
            :height="'100px'"
          />
          <!--/ avatar -->
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75 mt-75 d-block"
            @click="$refs.refInputEl.$el.click()"
          >
            Carica
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept="image/*"
            :hidden="true"
            plain
            @input="profileFile !== null ? previewNewImage(profileFile) : ''"
          />
          <!-- <div><label>Allowed only <span>PNG, JPG, GIF, SVG</span></label></div> -->
          <b-alert
            :show="errorPreviewBase64"
            variant="warning"
            class="mb-50"
          >
            <div class="alert-body">
              Formato immagine Non valido
            </div>
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="8"
        class=""
      >
        <validation-observer ref="organizzazione-general-validation-observer">
          <!-- form -->
          <b-form
            class=""
            @submit.prevent="validationForm"
          >
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  :label="'Denominazione Organizzazione'"
                  label-for="name-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Denominazione Organizzazione"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.nome"
                      :placeholder="'Denominazione organizzazione'"
                      name="name-organizzazione"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  :label="'Sottotitolo'"
                  label-for="sottotitolo-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sottotitolo"
                    rules=""
                  >
                    <b-form-input
                      v-model="organizzazione.sottotitolo"
                      name="sottotitolo-organizzazione"
                      :placeholder="'sottotitolo'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr class="my-50">
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  :label="'Indirizzo'"
                  label-for="address-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Indirizzo"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.indirizzo"
                      name="address-organizzazione"
                      :placeholder="'indirizzo'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="4"
                class="offset-md-2"
              >
                <b-form-group
                  label="Nazione"
                  label-for="nation-organizzazione"
                  label-class="font-weight-bolder"
                  class=""
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nazione"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.nazione"
                      name="nazione-organizzazione"
                      :placeholder="'nazione'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="4"
                md="2"
              >
                <b-form-group
                  :label="'CAP'"
                  label-for="capaddress-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CAP"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.cap"
                      name="capaddress-organizzazione"
                      :placeholder="'CAP'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="4"
              >
                <b-form-group
                  label="Città"
                  label-for="city-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Città"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.citta"
                      name="city-organizzazione"
                      :placeholder="'città'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="4"
                class="offset-md-2"
              >
                <b-form-group
                  label="Provincia"
                  label-for="prov-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Provincia"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.provincia"
                      name="provincia-organizzazione"
                      :placeholder="'provincia'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Codice Fiscale"
                  label-for="cf-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="codice fiscale"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.codiceFiscale"
                      name="cf-organizzazione"
                      :placeholder="'codice fiscale'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Partita Iva"
                  label-for="iva-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="p.iva"
                    rules="required"
                  >
                    <b-form-input
                      v-model="organizzazione.iva"
                      name="iva-organizzazione"
                      :placeholder="'p.iva'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Registro Imprese"
                  label-for="registro-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="registro imprese"
                    rules=""
                  >
                    <b-form-input
                      v-model="organizzazione.registroImprese"
                      name="registro-organizzazione"
                      :placeholder="'registro imprese'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Website"
                  label-for="website-organizzazione"
                  label-class="font-weight-bolder"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="website"
                    rules=""
                  >
                    <b-form-input
                      v-model="organizzazione.website"
                      name="website-organizzazione"
                      :placeholder="'website'"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        md="3"
        class="mt-5 mt-sm-2 offset-sm-1"
      >
        <div style="position:relative">
          <b-row style="position: absolute; top: -35px; right:0px">
            <b-col class="d-flex justify-content-end">
              <b-button
                v-b-modal="'recapito-organization-modal-event-handler'"
                variant="outline-primary"
                style="border-radius: 0"
              >
                <feather-icon
                  icon="PlusIcon"
                  class=""
                />
              </b-button>
            </b-col>
          </b-row>
          <b-list-group>
            <b-list-group-item
              v-for="recapito in organizzazione.recapiti"
              :key="recapito.id"
              class="d-flex justify-content-between"
            >
              <strong class="mr-1">
                {{ recapito.tipo }}
              </strong>
              <p class="m-0">
                <span>{{ recapito.valore }}</span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none p-0 align-self-end"
                  dropleft
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="deleteContact(recapito)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Elimina</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="editContact(recapito)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span>Modifica</span>
                  </b-dropdown-item>
                </b-dropdown>
              </p>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          type="submit"
        >
          Salva
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="resetForm"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
    <recapito-organization-modal
      ref="recapito-organization-modal-event-handler"
      @update-recapito="updateRecapito"
    />
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BImg, BAlert, BDropdown, BDropdownItem, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer, blobToBase64 } from '@core/comp-functions/forms/form-utils'
import RecapitoOrganizationModal from '../../../../../@core/components/settings/organization/RecapitoOrganizationModal.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BDropdown,
    BDropdownItem,
    RecapitoOrganizationModal,
    BListGroup,
    BListGroupItem,
    // BSkeletonWrapper,
    // BSkeleton,
    // BSkeletonImg,
    // BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      isChangedImage: false,
      profileFile: null,
      profileFileToBase64: '',
      errorPreviewBase64: false,
      required,
      locale: 'it',
      organizzazione: {
        avatar: '',
        nome: '',
        sottotitolo: '',
        indirizzo: '',
        cap: '',
        citta: '',
        nazione: '',
        codiceFiscale: '',
        iva: '',
        registroImprese: '',
        website: '',
        recapiti: [
          { id: 1, tipo: 'Tel', valore: '0339392' },
          { id: 2, tipo: 'email', valore: 'atorrisi@fai.business' },
          { id: 3, tipo: 'Fisso', valore: 'aaaaaa' },
        ],
      },
    }
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    resetForm() {
      this.isChangedImage = false
      this.errorPreviewBase64 = false
      this.profileFile = null
      // this.organizzazione = { ...this.$store.state.profile.generalReset }
    },
    /*     blobToBase641(blob) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }, */
    updateRecapito(recapito) {
      const findIndex = this.organizzazione.recapiti.findIndex(el => el.id === recapito.id)
      if (findIndex >= 0) {
        const recapiti2 = [...this.organizzazione.recapiti]
        recapiti2.push(recapito)
        this.organizzazione.recapiti.length = 0
        this.organizzazione.recapiti.push([...recapiti2])
      } else this.organizzazione.recapiti.push(recapito)
    },
    deleteContact(contact) {
      const findIndex = this.organizzazione.recapiti.findIndex(el => el.id === contact.id)
      this.organizzazione.recapiti.splice(findIndex, 1)
    },
    editContact(contact) {
      this.$refs['recapito-organization-modal-event-handler'].setRecapito(contact)
      this.$refs['recapito-organization-modal-event-handler'].showModal()
    },
    validationForm() {
      this.$refs['organizzazione-general-validation-observer'].validate(success => {
        if (success) {
          // console.log('ciaoo')
        }
      })
    },
    async previewNewImage(blob) {
      // console.log('previewNewImage', blob)
      // blobToBase64second(blob).then(res => console.log(res)).catch(e => e)
      // blob.size per impostare in bytes la dimensione massima del file
      await blobToBase64(blob).then(res => {
        // console.log('res blob', res)
        if (res.substring(0, 21).includes('image')) {
          this.organizzazione.avatar = res
          // this.optionsLocal.avatar = this.organizzazione.avatar
        } else {
          this.errorPreviewBase64 = true
        }
      }).catch(() => {
        this.errorPreviewBase64 = true
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    // const base64data = blobToBase64(this.profileFile)
    // console.log(base64data.substr(base64data.indexOf(',') + 1))
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    // const { blobToBase } = blobToBase64(this.profileFile)

    return {
      refInputEl,
      previewEl,
      // blobToBase64,
      // blobToBase,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
</style>
