<template>
  <b-tabs
    pills
  >
    <b-tab
      active
      title="Azienda"
    >
      <OrganizationTabs />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import OrganizationTabs from './tabs/OrganizationTabs.vue'
// import { getCombo } from '@/@core/api-service/shared/combo'

export default {
  components: {
    BTabs,
    BTab,
    OrganizationTabs,
  },
  mounted() {
  },
}
</script>
