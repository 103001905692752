var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"recapito-organization-modal-event-handler",attrs:{"id":"recapito-organization-modal-event-handler","centered":"","title":_vm.title,"hide-footer":"","visible":_vm.show,"no-close-on-backdrop":""},on:{"hidden":(function () {
    _vm.show = false
    _vm.resetModal()
  })}},[_vm._v(" s"+_vm._s(_vm.recapito)+" "),_c('validation-observer',{ref:"set-contact-form"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo di Contatto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Tipo di Contatto',"label-class":"font-weight-bolder"}},[_c('v-select',{staticClass:"mr-lg-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","filterable":"","options":_vm.optionContacts,"reduce":(function (option) { return option; }),"placeholder":"Tipo di Contatto"},model:{value:(_vm.recapito.tipo),callback:function ($$v) {_vm.$set(_vm.recapito, "tipo", $$v)},expression:"recapito.tipo"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo di Contatto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Indirizzo',"label-class":"font-weight-bolder"}},[_c('b-form-input',{attrs:{"placeholder":"valore"},model:{value:(_vm.recapito.valore),callback:function ($$v) {_vm.$set(_vm.recapito, "valore", $$v)},expression:"recapito.valore"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addContact}},[_vm._v("Salva")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }